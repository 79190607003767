import React from 'react';
import { Link } from 'gatsby';
import HelmetInfo from '../HelmetInfo';

import styles from './custom404.module.scss';

const Custom404 = () => (
  <div className={styles.wrapper}>
    <HelmetInfo is404 />
    <div className={styles.imageWrapper}>
      <img
        src="https://res.cloudinary.com/dewd4pral/image/upload/v1547967195/lilypad/hi-res-fav.png"
        alt=""
      />
    </div>
    <h1>Nothing to see&nbsp;here.</h1>
    <p>
      <Link to="/">Home</Link>
    </p>
  </div>
);

export default Custom404;
